import React from 'react'
import Head from 'next/head'
import LoginContainer from '../../src/containers/LoginContainer'

const Login = (props) => (
  <>
    <Head>
      <title>{props.t('headers.login')}</title>
    </Head>
    <LoginContainer {...props} />
  </>
)

export default Login
